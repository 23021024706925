import React from "react";
import { Box, Typography, Link } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { useTranslation } from "react-i18next";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const Contact = () => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                py: 5,
            }}
        >
            <Box
                sx={{
                    maxWidth: "1200px",
                    margin: "0 auto",
                }}
            >
                <Box
                    sx={{
                        mb: 4,
                        textAlign: "center",
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: "bold",
                            color: "secondary.main",
                            mb: 2,
                        }}
                    >
                        {t('contact')}
                    </Typography>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: "bold",
                        }}
                    >
                        {t('directBookings')}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            mt: 2,
                        }}
                    >
                        {t('offerQuestion')}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        gap: 3,
                    }}
                >
                    <Link
                        href="mailto:superior.sjl@gmail.com"
                        underline="none"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            p: 3,
                            borderRadius: 5,
                            backgroundColor: "background.paper",
                            textDecoration: "none",
                            "&:hover": {
                                backgroundColor: "grey.100",
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                width: 48,
                                height: 48,
                                backgroundColor: "primary.main",
                                color: "white",
                                mr: 2,
                            }}
                        >
                            <EmailIcon />
                        </Box>
                        <Box>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: "bold",
                                    mb: 0,
                                }}
                            >
                                {t('email')}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                    mb: 0,
                                }}
                            >
                                superior.sjl@gmail.com
                            </Typography>
                        </Box>
                    </Link>

                    <Link
                        href="tel:+48693912729"
                        underline="none"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            p: 3,
                            borderRadius: 5,
                            backgroundColor: "background.paper",
                            textDecoration: "none",
                            "&:hover": {
                                backgroundColor: "grey.100",
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                width: 48,
                                height: 48,
                                backgroundColor: "primary.main",
                                color: "white",
                                mr: 2,
                            }}
                        >
                            <PhoneIcon />
                        </Box>
                        <Box>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: "bold",
                                    mb: 0,
                                }}
                            >
                                {t('phone')} (10:00-20:00 CET)
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                    mb: 0,
                                    whiteSpace: "nowrap",
                                }}
                            >
                                +48 693 912 729
                            </Typography>
                        </Box>
                    </Link>

                    <Link
                        href="https://wa.me/48693912729"
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="none"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            p: 3,
                            borderRadius: 5,
                            backgroundColor: "background.paper",
                            textDecoration: "none",
                            "&:hover": {
                                backgroundColor: "grey.100",
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                width: 48,
                                height: 48,
                                backgroundColor: "green",
                                color: "white",
                                mr: 2,
                            }}
                        >
                            <WhatsAppIcon />
                        </Box>
                        <Box>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: "bold",
                                    mb: 0,
                                }}
                            >
                                WhatsApp (10:00-20:00 CET)
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                    mb: 0,
                                    whiteSpace: "nowrap",
                                }}
                            >
                                +48 693 912 729
                            </Typography>
                        </Box>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

export default Contact;
